import React, { Suspense } from 'react';
import { useEffect } from "react";

// import AOS from "aos";
// import "aos/dist/aos.css";

// ROUTER
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import RouterConfig from "./routing/RouterConfig";

import './App.scss';
import "typeface-montserrat";

import { ThemeProvider } from '@mui/material/styles';
import theme from './config/muiTheme';

import FallBack from './components/Fallback/Fallback';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => { window.scrollTo(0, 0) }, [pathname]);
  return null;
}

// function AnimateOnScroll() {
//   useEffect(() => {
//     AOS.init({
//       startEvent: 'DOMContentLoaded',
//       duration: 1000, // values from 0 to 3000, with step 50ms
//     });
//     AOS.refresh();
//   }, []);

//   return null;
// }

function App() {

  return (
    <Router>
      <ScrollToTop />
      {/* <AnimateOnScroll /> */}

      <ThemeProvider theme={theme}>

        <Suspense fallback={<FallBack />}>
          <div className="App">
            <RouterConfig />
          </div>
        </Suspense>

      </ThemeProvider>
    </Router>

  );
}

export default App;
