import React, { lazy } from 'react';
import { Routes, Route } from "react-router-dom";

import { ROOT, EVENTS, MARKETING, ENROLMENT, ABOUT, CAREERS, CONTACT, DOWNLOADSCHEDULE, COOKIES, PRIVACY, MANIFESTO, LOGIN, TERMS, REPORT, REPORT_FORM } from "../config/routes";

const Home = lazy(() => import('../pages/Home/Home'));
const About = lazy(() => import('../pages/About/About'));
const Events = lazy(() => import('../pages/Events/Events'));
const Marketing = lazy(() => import('../pages/Marketing/Marketing'));
const Enrolment = lazy(() => import('../pages/Enrolment/Enrolment'));
const Careers = lazy(() => import('../pages/Careers/Careers'));
const Positions = lazy(() => import('../pages/Positions/Positions'));
const Contact = lazy(() => import('../pages/ContactUs/Contact'));
const DownloadSchedule = lazy(() => import('../pages/DownloadSchedule/DownloadSchedule'));
const DumbPage = lazy(() => import('../pages/DumbPages/DumbPage'));
const NotFound = lazy(() => import('../pages/Errors/NotFound/NotFound'));
const Manifesto = lazy(() => import('../pages/Manifesto/Manifesto'));
const Login = lazy(() => import('../pages/Login/Login'));
const ReportForm = lazy(() => import('../pages/ReportForm/ReportForm'));


function RouterConfig() {
    return (
        <>
            <Routes>
                {/* List all public routes here */}

                {/* <Route path={EVENTS} element={<Navigate replace to={`${EVENTS}/recruitment/access-mba`} />} /> */}
                {/* <Route path={`${EVENTS}/:type`} element={<Events />} >
                    <Route path=":brand" element={<Events />} />
                </Route> */}

                <Route path={ROOT} element={<Home />} />
                <Route path={`${EVENTS}/:brand`} element={<Events />} />
                <Route path={`${MARKETING}/:brand`} element={<Marketing />} />
                <Route path={`${ENROLMENT}/:brand`} element={<Enrolment />} />
                <Route path={`${ABOUT}/:brand`} element={<About />} />
                <Route path={CAREERS} element={<Careers />} />
                <Route path={`${CAREERS}/positions`} element={<Positions />} />
                <Route path={CONTACT} element={<Contact />} />
                <Route path={PRIVACY} element={<DumbPage content={'privacy-policy'} />} />
                <Route path={COOKIES} element={<DumbPage content={'cookies'} />} />
                <Route path={DOWNLOADSCHEDULE} element={<DownloadSchedule />} />
                <Route path={MANIFESTO} element={<Manifesto />} />
                <Route path={LOGIN} element={<Login />} />
                <Route path={TERMS} element={<DumbPage content={'terms-and-conditions'} />} />
                <Route path={REPORT} element={<DumbPage content={'whistleblower-report'} />} />
                <Route path={REPORT_FORM} element={<ReportForm />} />

                <Route path="*" element={<NotFound />} />

                {/* <Route exact path={ROOT} component={Home} /> */}
            </Routes>
        </>
    );
};

export default RouterConfig;