export const ROOT = "/";
export const ABOUT = "/about";
export const EVENTS = "/events";
export const MARKETING = "/promotion";
export const ENROLMENT = "/connections";
export const CAREERS = "/careers";
export const CONTACT = "/contact";
export const DOWNLOADSCHEDULE = "/download-schedule";
export const COOKIES = "/cookies";
export const PRIVACY = "/privacy";
export const MANIFESTO = "/manifesto";
export const LOGIN = "/login";
export const TERMS = "/terms-and-conditions";
export const REPORT = "/whistleblower-report";
export const REPORT_FORM = "/whistleblower-report-form";



